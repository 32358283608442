import { Injectable } from '@angular/core';
import { Ingredient, IngredientType, NutritionalTableElement, Product, ProductSymbol } from '../_models/products/Product';
import { ProductFilter } from '../_models/products/ProductFilter';
import { ProductsResponse } from '../_models/products/ProductsResponse';
import { AuthService } from './auth.service';
import { FileService } from './file.service';
import { TranslationService } from './translation.service';
import * as _ from 'underscore';
import { TextsFormattingService } from './texts-formatting.service';

interface ProductPost
{
  product: Product;
  /**
   * Only if no new previews are sent in the postProduct
   */
  deletePreviews?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private authService: AuthService,
    private translationService: TranslationService,
    private fileService: FileService,
    private textFormattingService: TextsFormattingService)
  { }

  async getProduct(productId: string): Promise<Product>
  {
    const params: [string, string][] = [
      ['productId', productId]
    ];
    const result = await this.authService.authGet<Product>('/api/products/getProduct', params);
    return result;
  }

  async searchProducts(productFilter: ProductFilter ): Promise<ProductsResponse>
  {
    console.log('Filtering products by: ');
    console.log(productFilter);

    const response: ProductsResponse = await this.authService.authPost<ProductsResponse>('/api/products/searchProducts', productFilter);
    this.fixProductTimestamp(response.result);
    this.setProductIngredientsString(response.result);
    this.setProductDescriptionClean(response.result);
    this.setTableElementsTranslations(response.result);
    return response;
  }

  async saveProduct(product: Product, preview?: File, deletePreviews?: boolean): Promise<Product>
  {
    const formData = new FormData();

    const productPost: ProductPost =
    {
      product: product,
      deletePreviews: deletePreviews
    }

    console.log('Adding productPost to form...');
    console.log(productPost);
    formData.append('productPost', JSON.stringify(productPost));
    if (preview)
    {
      const fileExtension = `.${preview.name.split('.').pop()}`;
      const fileRawData = await this.fileService.toRaw(preview);
      formData.append('file', this.getRawBlob(fileRawData), `product${fileExtension}`);
    }
    const result = await this.authService.authPost<Product>(`/api/products/postProduct`, formData);
    return result;
  }

  async deleteProducts(product: Product): Promise<void>
  {
    await this.authService.authPost('/api/products/deleteProduct', product);
  }

  async cloneProduct(productId: string): Promise<Product>
  {
    const params: [string, string][] = [
      ['productId', productId]
    ];
    const result = await this.authService.authGet<Product>('/api/products/cloneProduct', params);
    return result;
  }

  async archiveProductConfig(productId: string)
  {
    const params: [string, string][] = [
      ['productId', productId]
    ];
    await this.authService.authGet<void>('/api/products/archiveProduct', params);
  }

  async recoverProductConfig(productId: string)
  {
    const params: [string, string][] = [
      ['productId', productId]
    ];
    await this.authService.authGet<void>('/api/products/recoverProduct', params);
  }

  async getIngredients(): Promise<Ingredient[]>
  {
    const result = await this.authService.authGet<Ingredient[]>('/api/products/getIngredients');
    return result;
  }

  async getIngredientTypes(): Promise<IngredientType[]>
  {
    const result = await this.authService.authGet<IngredientType[]>('/api/products/getIngredientTypes');
    return result;
  }

  async getNutritionalTableLements(): Promise<NutritionalTableElement[]>
  {
    const result = await this.authService.authGet<NutritionalTableElement[]>('/api/products/getNutritionalTableLements');
    return result;
  }

  async getProductSymbols(): Promise<ProductSymbol[]>
  {
    const result = await this.authService.authGet<ProductSymbol[]>('/api/products/getProductSymbols');
    return result;
  }

  async getProductNumberByCode(productCode: string): Promise<number[]>
  {
    const identity = this.authService.getCurrentIdentity();
    const params: [string, string][] = [
      ['productCode', productCode],
      ['companyId', identity.company?.id]
    ];
    const result = await this.authService.authGet<number[]>('/api/products/getProductNumberByCode', params);
    return result;
  }

  async validateReferenceCode(code: string): Promise<boolean>
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity.company.id;
    const params: [string, string][] = [
      ['code', code],
      ['companyId', companyId]
    ];
    const result = await this.authService.authGet<boolean>('/api/products/validateReferenceCode', params);
    return result;
  }

  async downloadProductsReport(productFilter: ProductFilter): Promise<void>
  {
    let url: string;
    try
    {
      url = await this.getProductsReportUrl(productFilter);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Products_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        this.revokeReportUrl(url);
      }
    }
  }

  async getProductsReportUrl(filter: ProductFilter): Promise<string>
  {
    const data = await this.authService.authPost<Blob>('/api/products/DownloadProductsReport', filter, true);
    return window.URL.createObjectURL(data);
  }

  revokeReportUrl(url: string)
  {
    window.URL.revokeObjectURL(url);
  }

  private fixProductTimestamp(products: Product[])
  {
    for (const p of products)
    {
      p.lastModificationTimestampUtc = new Date(p.lastModificationTimestampUtc);
    }
  }

  private getRawBlob(fileContent: ArrayBuffer): Blob
  {
    const result = new Blob([fileContent]);
    return result;
  }

  private setProductIngredientsString(products: Product[])
  {
    products.forEach(product =>
    {
      let groupedIngredients: string[] = [];
      const requiredIngredients = product.ingredients?.filter(i => i.ingredient.required).sort(i => i.order);
      requiredIngredients?.forEach(ri =>
      {
        const translatedName = this.getIngredientTranslation(ri.ingredient.name);
        groupedIngredients.push(translatedName);
      });

      const nonRequiredIngredients = product.ingredients?.filter(i => !i.ingredient.required).sort(i => i.order);
      nonRequiredIngredients.sort((a, b) => a.order - b.order);
      const ingredientTypes = _.uniq(nonRequiredIngredients?.map(i => i.ingredient.type), type => type.id);
      ingredientTypes?.forEach(type =>
      {
        if (type)
        {
          const ingredientsOfType = nonRequiredIngredients?.filter(i => i.ingredient.typeId === type.id);
          const typeTranslation = this.getIngredientTypeTranslation(type.name);
          const ingredientsOfTypeString = ingredientsOfType?.map(i => i.ingredient.name);
          const translatedIngredients: string[] = [];
          ingredientsOfTypeString.forEach(iName =>
          {
            const trasnlatedIngredient = this.getIngredientTranslation(iName);
            translatedIngredients.push(trasnlatedIngredient);
          });
          groupedIngredients.push(`${typeTranslation} (${translatedIngredients.join(', ')})`);
        }
      });

      product.ingredientsString = groupedIngredients.join(', ');
    });
  }

  private setTableElementsTranslations(products: Product[])
  {
    products.forEach(product =>
    {
      product.nutritionalTableElements?.forEach(pElement =>
      {
        const tableElement = pElement.nutritionalTableElement;
        tableElement.translatedName = this.translationService.getTranslation(`lid.srv.Products.NutritionalTableElement.${tableElement.name}`);
      });
    });

  }

  private getIngredientTranslation(ingredientName: string)
  {
    return this.translationService.getTranslation(`lid.srv.Products.Ingredients.${ingredientName}`);
  }

  private getIngredientTypeTranslation(ingredienTypetName: string)
  {
    return this.translationService.getTranslation(`lid.srv.Products.IngredientTypes.${ingredienTypetName}`);
  }

  private setProductDescriptionClean(products: Product[])
  {
    products.forEach(product =>
    {
      if(product.description)
      {
        product.descriptionClean = this.textFormattingService.formatHtml(product.description, true, false, false);
      }
    });
  }
}
