import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { Identity } from '../_models/user/Identity';
import { AuthService } from './auth.service';
import { MoonDeskDocument } from '../_models/document/Document';
import { Company } from '../_models/configuration/Company';
import { FileNameConfig } from '../_models/configuration/FileNameConfig';
import { Class, ClassValue } from '../_models/configuration/Class';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentNameService
{
  constructor(private authService: AuthService, private docService: DocumentService)
  {
    this.authService.identityChanged.subscribe(newIdentity =>
    {
      this.updateIdentity(newIdentity);
    });
    this.updateIdentity(this.authService.getCurrentIdentity());
  }

  private updateIdentity(newId: Identity)
  {
    if (!newId.company)
    {
      return;
    }
    const company = newId.company;
    if (company.fileNameConfigs == null)
    {
      company.fileNameConfigs = [];
    }
    if (!_.some(company.fileNameConfigs, fc => fc.fileType === 'Export'))
    {
      company.fileNameConfigs.push({companyId: company.id, fileType: 'Export', fileFormat: '[company]'});
    }
    if (!_.some(company.fileNameConfigs, fc => fc.fileType === 'InWork'))
    {
      company.fileNameConfigs.push({companyId: company.id, fileType: 'InWork', fileFormat: '[company]'});
    }
  }

  /**
   * Checks if a configured filename is valid. Returns an error string if some
   * class or field (code) doesn't exist. Null if everything's fine.
   */
  checkFilenameConfig(text: string): string
  {
    if (text === '')
    {
      return 'Empty text';
    }
    const id = this.authService.getCurrentIdentity();
    const fieldTypes = id.company.fieldTypes;
    const classes = this.docService.getPlainClassList(id.company.classes);

    // [class_<className>] check
    let classNames: string[] = this.getClassNames(text, false);
    for (const clsName of classNames)
    {
      if (!_.some(classes, cls => cls.name.toLowerCase() === clsName.toLowerCase()))
      {
        return `Class '${clsName}' does not exist`;
      }
    }
    // [classcode_<classcode>] check
    classNames = this.getClassNames(text, true);
    for (const clsName of classNames)
    {
      if (!_.some(classes, cls => cls.name.toLowerCase() === clsName.toLowerCase()))
      {
        return `Class '${clsName}' does not exist`;
      }
    }
    // [code_<fieldType>] check
    const fieldNames = this.getFieldNames(text);
    for (const fieldName of fieldNames)
    {
      if (!_.some(fieldTypes, ft => ft.code.toLocaleLowerCase() === fieldName.toLocaleLowerCase()))
      {
        return `Field '${fieldName}' does not exist`;
      }
    }

    return null;
  }

  private createFilename(configuration: string, moonDeskDocument: MoonDeskDocument)
  {
    const identity: Identity = this.authService.getCurrentIdentity();
    let fieldNames: string[];
    let classNames: string[];
    // Set field values names
    fieldNames = this.getFieldNames(configuration);
    for (const fieldName of fieldNames)
    {
      const actualFieldValue = _.find(moonDeskDocument.latestVersion.fieldValues,
                                      fv => fv && fv.fieldType && fv.fieldType.code &&
                                      fv.fieldType.code.toLowerCase() === fieldName.toLowerCase());
      if (actualFieldValue)
      {
        configuration = configuration.replace('[' + fieldName + ']', actualFieldValue.value);
      }
      else
      {
        configuration = configuration.replace('[' + fieldName + ']', '');
      }
    }
    // Set class values names
    classNames = this.getClassNames(configuration, false);
    for (const className of classNames)
    {
      const classValueNames = this.getClassValueString(moonDeskDocument, className, 'name');
      configuration = configuration.replace('[class_' + className + ']', classValueNames);
    }
    // Set class codes
    classNames = this.getClassNames(configuration, true);
    for (const className of classNames)
    {
      const classValueCodes = this.getClassValueString(moonDeskDocument, className, 'code');
      configuration = configuration.replace('[classcode_' + className + ']', classValueCodes);
    }
    // Set company
    configuration = configuration.replace('[company]', identity.company.name);
    if (!moonDeskDocument.documentType || !moonDeskDocument.documentType.name)
    {
      moonDeskDocument.documentType = _.find(identity.company.documentTypes, dt => dt.id === moonDeskDocument.documentTypeId);
    }
    // Set document type
    configuration = configuration.replace('[documenttype]', moonDeskDocument.documentType ? moonDeskDocument.documentType.name : '');
    configuration = configuration.replace('[documenttypecode]', moonDeskDocument.documentType?.code ? moonDeskDocument.documentType.code : '');
    // Set document version
    const version = `${moonDeskDocument.latestMayorVersionNumber}`;
    configuration = configuration.replace('[version]', `${moonDeskDocument.latestMayorVersionNumber ?? ''}`);
    // Set document number
    configuration = configuration.replace('[documentnumber]', moonDeskDocument.number ? moonDeskDocument.number.toString() : '');
    configuration = configuration.trim();
    if (!configuration || configuration === '')
    {
      configuration = `${identity.company.name}${moonDeskDocument.number ? '_' + moonDeskDocument.moonNumber : ''}`;
    }

    return configuration.trim();
  }

  private getClassValueString(document: MoonDeskDocument, className: string, mode: 'name' | 'code'): string
  {
    const identity: Identity = this.authService.getCurrentIdentity();
    const classValues: ClassValue[] = [];
    let result: string = '';
    let plainClassList: Class[];
    if (identity && identity.company)
    {
      plainClassList = this.docService.getPlainClassList(identity.company.classes);
    }

    for (const cv of document.classValues)
    {
      if (!cv.class)
      {
        cv.class = _.find(plainClassList, cls => cls.id === cv.classId);
      }
      if (cv.class.name.toLowerCase() === className.toLowerCase())
      {
        classValues.push(cv);
      }
    }

    if (mode === 'name')
    {
      result = _.map(classValues, cv => cv.name).join('-');
    }
    else if (mode === 'code')
    {
      result = _.map(classValues, cv => cv.code).join('-');
    }

    return result;
  }

  private getFieldNames(text: string)
  {
    const exprCode = /\[code_(.+?)\]/g;
    const fieldNames: string[] = [];
    const fieldMatch = text.match(exprCode);
    if (fieldMatch)
    {
      for (const value of fieldMatch)
      {
        const fieldName = value.split(/\[(.+)\]/);
        fieldNames.push(fieldName[1]);
      }
    }
    return fieldNames;
  }

  private getClassNames(text: string, forCode: boolean)
  {
    let exprEntity;
    if (forCode)
    {
      exprEntity = /(\[classcode_(.+?)\])/g;
    }
    else
    {
      exprEntity = /(\[class_(.+?)\])/g;
    }
    const classNames: string[] = [];
    const classMatch = text.match(exprEntity);
    if (classMatch)
    {
      for (const value of classMatch)
      {
        const className = value.split(/_(.+)\]/);
        classNames.push(className[1]);
      }
    }
    return classNames;
  }

  getFileFormat(docCompanyId: string, fileType: 'Export'|'InWork'): string
  {
    const id = this.authService.getCurrentIdentity();
    if (!id.company)
    {
      throw Error('No company');
    }
    if (id.company.id !== docCompanyId)
    {
      throw Error('Wrong company');
    }
    const result = this.getFileNameConfig(id.company.fileNameConfigs, fileType);
    return result.fileFormat;
  }

  private getFileNameConfig(configs: FileNameConfig[], fileType: 'InWork'|'Export'): FileNameConfig
  {
    const result = _.find(configs, fc => fc.fileType === fileType);
    if (!result)
    {
      throw Error('filetype not available');
    }
    return result;
  }

  /**
   * To get the filename for a document
   * @param filetype if InWork or Export
   * @param moonDeskDocument The document (its classValues and fieldValues are used, depending on the filetype/configuration)
   * @param suffix Optional, added before fileExtension
   * @param fileExtension .ai or .whatever
   */
  createDocumentName( filetype: 'InWork' | 'Export',
                      moonDeskDocument: MoonDeskDocument,
                      suffix: string,
                      fileExtension: string,
                      replaceUnderscore?: boolean)
  {
      const fileFormat = this.getFileFormat(moonDeskDocument.companyId, filetype);
      let docname = this.createFilename(fileFormat, moonDeskDocument);

      docname = this.cleanString(docname);

      if (suffix)
      {
        docname = `${docname}${suffix}`;
      }
      if (fileExtension)
      {
        docname = `${docname}${fileExtension}`;
      }
      docname = docname.replace(/\s/g, '_');
      if (replaceUnderscore)
      {
        docname = docname.replace(/_/g, ' ');
      }
      if (docname.startsWith('-'))
      {
        // Leading - signs are being changed by Illustrator when exporting .png or .jpg
        // To keep consistency between different export files, we adapt the base name
        // for all archives (appeared in bug 8687)
        docname = '_' + docname.substring(1, docname.length);
      }
      
      return docname;
  }

  private cleanString(name: string): string
  {
      let result = name.replace(/ñ/g, 'n');
      result = result.replace(/Ñ/g, 'N');
      const regex = /[^A-Za-z0-9-_ ]/ig;
      result = result.replace(regex, '');
      // BUG 8887 !
      // result.length major than ~114 will result in a full path longer than 260 and
      // will exceed the longest path (260) accepted by ExtendedScript.
      // so we set a substring of 100 to allow a margin between the filename and the user's path to their folder (root/filename)
      result = result.substr(0, 100);
      return result;
  }
}
