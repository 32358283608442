import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { PendingAccount, UserResponse } from '../_models/user/Registration';
import { MicrosoftOAuthConfiguration, AdobeOAuthConfiguration } from '../_models/api/OAuthConfiguration';
import { User } from '../_models/user/User';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';

@Injectable({
    providedIn: 'root'
  })
  export class OAuthService
  {
    constructor(
      private authService: AuthService,
      @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider)
    {
    }

    async loginMicrosoft(state: string, loginHint?: string, redirectToSubscription: boolean = false)
    {
      const url = await this.getMicrosoftLoginUrl(state, loginHint, redirectToSubscription);
      window.location.replace(url);
    }

    async getMicrosoftLoginUrl(state: string, loginHint?: string, redirectToSubscription: boolean = false): Promise<string>
    {
      const msConfig = await this.getMSOAuthConfiguration();
      const redirectUri = this.getMsRedirectUrl(redirectToSubscription);
      const url = this.getMsOauthUrl(msConfig.clientId, redirectUri, state, loginHint);
      return url;
    }

    /**
     * Generate the redirect URL for a user to login with a Microsoft account.
     * - The clientId is the id of the Active Directory application.
     * - The redirect URL must be configured in that application (https!)
     * - If an email is given, it will be preselected in the login box.
     */
    private getMsOauthUrl(clientId: string, redirect: string, state: string, email?: string): string
    {
      let url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?` +
                  `client_id=${clientId}` +
                  '&response_type=code' +
                  `&redirect_uri=${encodeURI(redirect)}` +
                  '&scope=User.Read' +
                  '&response_mode=query' +
                  '&state=' + state;
      if (email)
      {
        url = `${url}&login_hint=${encodeURI(email)}&prompt=login`;
      }
      else
      {
        url = `${url}&prompt=select_account`;
      }
      return url;
    }

  getMsRedirectUrl(redirectToSubscription: boolean): string
  {
    // In web, the baseurl is '' (for api calls) and we use location.origin, but in extension, it's the configured backend
    const baseUrl = this.platformInfoProvider.getWebUrl();
    const path = redirectToSubscription ? '/completeoauth/mssubscription' : '/completeoauth/ms';
    const redirectUri = `${baseUrl}${path}`;
    return redirectUri;
  }

    async finishMicrosoftLogin(code: string, state: string, isSubscriptionPage: boolean = false): Promise<PendingAccount>
    {
        const redirect = this.getMsRedirectUrl(isSubscriptionPage);
        const params: [string, string][] = [
          ['code', code],
          ['state', state],
          ['redirect', redirect]
        ];
        const x = await this.authService.get<PendingAccount>('/api/auth/GetAccountForMicrosoftOauth', params);
        if (x.user && !x.user.pending)
        {
          await this.authService.handleLogin(x.user, undefined, undefined);
        }
        return x;
    }

    public async getMSOAuthConfiguration(): Promise<MicrosoftOAuthConfiguration>
    {
      const result = await this.authService.get<MicrosoftOAuthConfiguration>('/api/auth/getMSOAuthConfiguration');
      return result;
    }

    async loginAdobe()
    {
      // https://www.adobe.io/authentication/auth-methods.html#!AdobeDocs/adobeio-auth/master/OAuth/OAuth.md#authentication
      const adobeConfig = await this.getAdobeOAuthConfiguration();
      const url = `https://ims-na1.adobelogin.com/ims/authorize?` +
                  `client_id=${adobeConfig.clientId}` +
                  '&response_type=code' +
                  `&redirect_uri=${adobeConfig.redirectUri}` +
                  '&response_mode=query' +
                  '&scope=openid';
                  // '&state=12345';
        window.location.replace(url);
    }

    public async getAdobeOAuthConfiguration(): Promise<AdobeOAuthConfiguration>
    {
      const result = await this.authService.get<AdobeOAuthConfiguration>('/api/auth/getAdobeOAuthConfiguration');
      return result;
    }

    async finishAdobeLogin(code: string, state: string): Promise<User>
    {
        const params: [string, string][] = [
          ['code', code],
          ['state', state]
        ];
        const x = await this.authService.get<User>('/api/auth/loginadobe', params);
        return x;
    }

    async isOauthLoginDone(provider: 'Microsoft', state: string): Promise<UserResponse>
    {
      const params: [string, string][] = [
        ['provider', provider],
        ['state', state]
      ];
      const x = await this.authService.get<UserResponse>('/api/auth/isOauthLoginDone', params);
      return x;
    }
  }
