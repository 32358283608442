import { Inject, Injectable } from '@angular/core';
import { MoonDeskDocument } from '../_models/document/Document';
import * as _ from 'underscore';
import { AuthService, Progress } from './auth.service';
import { DocumentData, DocumentService } from '../services/document.service';
import { DocumentPost, LibraryProductLinkData } from '../_models/document/DocumentPost';
import { DocumentVersion } from '../_models/document/DocumentVersion';
import { DocumentRawData, PlatformInfoProvider } from '../public_api';
import { FileService } from './file.service';
import { DocumentVersionMetadata } from '../_models/document/DocumentVersionMetadata';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(
    private authService: AuthService,
    private documentService: DocumentService,
    private fileService: FileService,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider
  )
  {}


  public async checkDuplicate(file: File): Promise<DocumentVersion | undefined>
  {
    const identity = this.authService.getCurrentIdentity();
    const checksum = await this.fileService.getFileChecksum(file);
    const duplicateElements = await this.documentService.getDocumentVersionsForChecksums(identity.company.id, [checksum]);
    if (duplicateElements && duplicateElements.length > 0)
    {
      duplicateElements[0].uniqueExportName = file.name;
      return duplicateElements[0];
    }
    return undefined;
  }

  public async postLibraryElementFromWeb(
    file: File,
    progressCallback: (progress: Progress) => void,
    libraryProductLinkData?: LibraryProductLinkData): Promise<MoonDeskDocument>
  {
    const checksum = await this.fileService.getFileChecksum(file);
    const content = await this.fileService.toRaw(file);
    const extRegex = /(?:\.([^.]+))?$/;
    let ext = extRegex.exec(file.name)[0];
    ext = ext ? ext.toLowerCase() : undefined;
    const filename = file.name.replace(extRegex, '');

    const identity = this.authService.getCurrentIdentity();
    const libraryDocType = _.find(identity.company.documentTypes, docType => docType.isLibraryType);

    if (!libraryDocType)
    {
      throw new Error('Missing library document type');
    }


    const id = await this.documentService.getNewDocumentId(true);
    const document: MoonDeskDocument =
    {
      id: id,
      number: 0,
      company: {id: identity.company.id},
      documentType: {id: libraryDocType.id, companyId: null, name: null, classes: [], fieldTypes: [], isLibraryType: true},
      classValues: [],
      latestVersion: {
        documentTags: [{value: filename}],
        fieldValues: [],
        fileType: ext,
        checksum: checksum,
        metadata: new DocumentVersionMetadata()
      }
    };

    const documentPost: DocumentPost =
    {
      document: document,
      isMayorVersion: true,
      markApproved: true,
      taskActions: [],
      uploadedFromWeb : ((await this.platformInfoProvider.getFrontendInfo()).frontendName === 'WEB'),
      libraryProductLinkData: libraryProductLinkData
    };

    let result: MoonDeskDocument;
    try
    {
      const data: DocumentRawData =
      {
        fileType: ext,
        fileContent: <ArrayBuffer>content
      };
      result = await this.documentService.postDocumentRaw(
        documentPost,
        undefined,
        data,
        progress => progressCallback(progress)
      );
      result.latestVersion.imageUrl = result.previewImageUrl;
      return result;
    }
    catch (err)
    {
      throw err;
    }
  }
}
