<div class="popup ask-dialog">

    <div class="header" [ngClass]="{'info-header' : data.mode ==='info',
                                    'warning-header' : data.mode === 'warning'}">
        <span class="title" *ngIf="!data.title" translate>lid.web2.ask-dialog.pleaseConfirm</span>
        <span class="title" *ngIf="data.title">{{data.title}}</span>
        <mat-icon *ngIf="data.mode === 'info'" class="notranslate close-icon">info</mat-icon>
        <mat-icon *ngIf="data.mode === 'warning'" class="notranslate close-icon">warning</mat-icon>
    </div>

    <div class="body" [ngClass]="theme">
        <div class="subtitle" *ngIf="data.subtitle">{{data.subtitle}}</div>
        <div class="description" *ngIf="data.description">{{data.description}}</div>
        <div class="docs" *ngIf="data.docs && data.docs.length > 0">
            <div class="doc" *ngFor="let doc of data.docs">{{doc}}</div>
        </div>
        <div class="label" *ngIf="data.question">{{data.question}}</div>
    </div>

    <div class="actions">
        <ng-container *ngIf="!data.notifyMode">
            <ng-container *ngIf="theme !== 'recover-rule'">
                <button e2e="task-accept-button" class="moon-btn def-btn r-4 full-light-btn action cancel-btn" mat-dialog-close="yes" translate>lid.web2.ask-dialog.yes</button>
                <button e2e="task-closed-button" class="moon-btn def-btn r-4 full-primary-btn action" (click)="close()" translate>lid.web2.ask-dialog.no</button>
            </ng-container>
            <ng-container *ngIf="theme === 'recover-rule'">
                <button e2e="task-closed-button" class="moon-btn def-btn r-4 full-light-btn action cancel-btn" (click)="close()" translate>lid.web2.ask-dialog.cancel</button>
                <button e2e="task-accept-button" class="moon-btn def-btn r-4 full-light-btn action cancel-btn" mat-dialog-close="yes" translate>lid.web2.ask-dialog.continue</button>
            </ng-container>
        </ng-container>

        <button e2e="task-closed-button" *ngIf="data.notifyMode" class="moon-btn def-btn r-4 full-primary-btn action" (click)="close()" translate>lid.web2.ask-dialog.continue</button>

    </div>

</div>
